import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/recharge/order/list',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(id) {
  return http({
    url: '/company/recharge/order/detail',
    method: 'get',
    params: { id }
  })
}

// del
export function delAPI(id) {
  return http({
    url: '/company/recharge/order/delete',
    method: 'post',
    data: { id }
  })
}
// stat
export function getStatAPI(params) {
  return http({
    url: '/company/recharge/order/static',
    method: 'get',
    params
  })
}
