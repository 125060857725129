<template>
  <div class="wrapper" style="width: 100%">
    <div class="btn-back">
      <div>
        <admin-title :title="$route.meta.name"></admin-title>
      </div>
      <div>
        <el-button type="info" @click="$router.back()" size="mini">返回</el-button>
      </div>
    </div>
    <div class="title">用户信息</div>
    <el-descriptions direction="vertical" :column="6" border>
      <el-descriptions-item label="昵称" :span="1" label-class-name="my-label">{{ user?.nickname }}</el-descriptions-item>
      <el-descriptions-item label="姓名" :span="1" label-class-name="my-label">{{ user?.realname }}</el-descriptions-item>
      <el-descriptions-item label="手机号" :span="1" label-class-name="my-label">{{ user?.mobile }}</el-descriptions-item>
      <el-descriptions-item label="头像" :span="1" label-class-name="my-label">
        <el-image style="width: 60px; height: 60px" :src="user?.face" :preview-src-list="[user?.face]"></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="余额" :span="1" label-class-name="my-label">{{ user?.balance }}</el-descriptions-item>
      <el-descriptions-item label="最后一次登录时间" :span="1" label-class-name="my-label">{{ user?.last_login_at }}</el-descriptions-item>
    </el-descriptions>
    <div class="title">订单信息</div>
    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="订单号" :span="1" label-class-name="my-label">{{ detail.order_no }}</el-descriptions-item>
      <el-descriptions-item label="流水号" :span="1" label-class-name="my-label">{{ detail.serial_no }}</el-descriptions-item>
      <el-descriptions-item label="充值金额" :span="1" label-class-name="my-label">{{ detail.recharge_amount }}</el-descriptions-item>
      <el-descriptions-item label="订单金额" :span="1" label-class-name="my-label">{{ detail.order_amount }}</el-descriptions-item>
      <el-descriptions-item label="支付金额" :span="1" label-class-name="my-label">{{ detail.pay_amount }}</el-descriptions-item>
      <el-descriptions-item label="赠送金额" :span="1" label-class-name="my-label">{{ detail.gift_amount }}</el-descriptions-item>
      <el-descriptions-item label="支付时间" :span="1" label-class-name="my-label">{{ detail.pay_time }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { getDetailAPI } from './api'
export default {
  name: 'Detail',

  data() {
    return {
      detail: {}
    }
  },

  mounted() {
    this.getDetail()
  },
  computed: {
    user() {
      return this.detail.user
    }
  },
  methods: {
    async getDetail() {
      this.detail = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  .btn-back {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .title {
    margin: 10px 0;
    font-weight: 600;
  }
}
</style>
